<template>
  <div class="pb-2">
    <h1 class="text-left font-medium-5 font-weight-bolder mb-2">
      {{ $t('Discounts') }}
    </h1>
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableColumnsDiscounts"
      back-to-list-path="settings-catalog-price-list"
      :table-config-options="{
        endpoint: `${MODULE_NAME}/trashList`,
      }"
    >
      <template #cell(actions)="{ data }">
        <div
          class="d-flex justify-content-start"
        >
          <feather-icon
            v-b-tooltip.noninteractive.hover
            icon="LTrashIcon"
            :class="'lightIcon cursor-pointer border-dotted featherIcon'"
            size="16"
            :title="$t('Delete')"
            @click="remove(data)"
          />
          <feather-icon
            v-b-tooltip.noninteractive.hover
            :title="$t('Set to active')"
            icon="LLoadIcon"
            size="24"
            class="featherIcon cursor-pointer border-dotted"
            @click="restoreContact(data.item.id)"
          />
        </div>
      </template>
      <template #cell(time_period)="data">
        <div v-if="data.data.item">
          <span>{{ data.data.item.start ? data.data.item.start : 'Any time' }}</span>
          <span>-</span>
          <span>{{ data.data.item.end ? data.data.item.start : 'Any time' }}</span>
        </div>
      </template>
      <template #cell(rate)="data">
        <div class="text-center">
          {{ data.data.item.rate }}<span>%</span>
        </div>
      </template>
      <template #cell(position)="data">
        <div class="text-center">
          {{ data.data.item.position }}
        </div>
      </template>
      <!--      <template #filter>-->
      <!--        {{ '' }}-->
      <!--      </template>-->
    </l-table>
  </div>
</template>

<script>

// eslint-disable-next-line import/no-cycle
import { title } from '@core/utils/filter'
import {
  VBTooltip,
} from 'bootstrap-vue'
import LTable from '@/views/components/LTable/LTable.vue'
import tableConfig from '../pricingConfig'

export default {
  name: 'PricingTiers',
  components: {
    LTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      selected: '',
      isLoadingOfSetDisabled: false,
      idOfSettledDefault: null,
    }
  },
  created() {
    this.$emit('updateMenu', 'settings-catalog-pricing-tiers-list')
  },
  methods: {
    restoreContact(data) {
      this.confirmNotification(this, [data], `${this.MODULE_NAME}/restoreFromTrashedList`, {
        title: 'Are you sure you want to reactivate this ?',
        text: '',
        confirmButtonText: 'Reactivate',
      })
        .then(() => {
          this.refetchData()
        })
    },
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    remove(data) {
      const { item } = data

      this.confirmNotification(this, item, `${this.MODULE_NAME}/del`)
        .then(() => {
          this.refetchData()
        })
    },
  },
  setup() {
    const MODULE_NAME = 'discounts'
    const {
      tableColumnsDiscounts,
    } = tableConfig()
    return {
      tableColumnsDiscounts,
      MODULE_NAME,
      title,
    }
  },
}
</script>

<style>
</style>
